import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
require('@/assets/css/styles.css'); // Included the style css {main css}
require('@/assets/css/slick.min.css'); // Included the style css {main css}
global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;
// require('@/assets/css/slick-theme.min.css'); // Included the style css {main css}
createApp(App).use(router).mount('#app')
